import {Image, Card, Input, Modal, Result, Select, Spin, Flex, Button, message} from "antd";
import {EditOutlined, ShopOutlined} from "@ant-design/icons";
import {useLocation, useNavigate} from "react-router-dom";
import {DataContext} from "../../store/DataContext";
import {useContext, useEffect, useState,} from "react";
import {getOrderById, getOrderList, newsOption, saveOrder} from "../../config/api";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";

const Evaluate = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()
    const [order, setOrder] = useState({})
    const [orderState, setOrderState] = useState(0)   // 0 鉴定中 1 图片异常 2 已完成 3价格异常
    const {darkMode} = useContext(DataContext);
    const [selectOption, setSelectOption] = useState(0)
    const [remark, setRemark] = useState("")
    const [signatureRemark, setSignatureRemark] = useState("")
    const signatureArr = ["10","9","8","7","6","低于6"]
    const BGSArr = ["黑10","10","9.5","9","8.5","8","7.5","低于7.5"]
    const PSAArr = ["10","9","8","7","6","5","低于5"]
    useEffect(() => {
        let queryMessage = location.search.slice(1)
        if (queryMessage !== "") {
            console.log(queryMessage)
            getOrderById({id: queryMessage}).then(r => {
                setData(r)
                console.log(r)
                let pictures = [r.faceShot, r.backShot, ...r.flawList]
                if (r.signature) {
                    pictures.push(r.signature)
                }
                if (r.extraImages?.length > 0) {
                    pictures.push(...r.extraImages)
                }
                let newOrder = {...r, createTime: dayjs(r.createTime).format('YYYY-MM-DD'), pictures}
                setOrder(newOrder)
                setOrderState(r.state)
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, [])
    const submit = () => {
        console.log("xxx")
        if (orderState === 0) {
            message.error("请选择预评结果").then()
            return
        }
        if (orderState === 2 && remark === "") {
            message.error("请选择评分").then()
            return
        }
        if (orderState === 2 && order?.signature && order?.signature !== "" && signatureRemark === "") {
            message.error("请选择签字评分").then()
            return
        }
        if (orderState === 1 && document.getElementById("errorInput").value === "") {
            message.error("请给出异常提示").then()
            return
        }
        let newData = {...data, state: orderState}
        if (orderState === 1) {
            newData.errTip = document.getElementById("errorInput").value
        }
        if (orderState === 2) {
            newData.remark = remark
        }
        if (orderState === 2 && order?.signature && order?.signature !== "") {
            newData.signatureRemark = signatureRemark
        }

        console.log(newData)


        saveOrder({...newData}).then(r => {
            console.log(r)
            navigate("/preMark/")
        })
    }
    return <Spin spinning={loading} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        {
            !loading && Object.keys(order).length === 0 ? <Result
                status="info"
                // title="暂无订单"
                subTitle="暂未找到订单"
            /> : loading ? null : <Card title="评价">
                <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>

                    <Card>
                        <div style={{fontSize: "16px", fontWeight: 1000}}><ShopOutlined/> {order.merchant}</div>
                        <div style={{display: "flex", gap: "5px", marginTop: "10px"}}>
                            <div style={{
                                width: "50px",
                                textAlign: "center",
                                padding: "4rpx 10px",
                                fontSize: "12px",
                                fontWeight: 800,
                                color: "#272949",
                                borderRadius: "30px",
                                boxSizing: "borderBox",
                                border: "2px solid gray",
                                backgroundColor: "white"
                            }}>{order.cardType}</div>
                            <div style={{
                                width: "50px",
                                textAlign: "center",
                                padding: "4rpx 10px",
                                fontSize: "12px",
                                fontWeight: 800,
                                color: "#272949",
                                borderRadius: "30px",
                                boxSizing: "borderBox",
                                border: "1px solid black",
                                backgroundColor: "white"
                            }}>{order.thickness}</div>
                        </div>
                        <div style={{display: "flex", gap: "10px", flexWrap: "wrap", marginTop: "10px"}}>
                            {
                                order.pictures?.map((item, index) => {
                                    return <Image
                                        key={index}
                                        width={200}
                                        src={item}
                                    />
                                })
                            }
                        </div>

                        {
                            order.content !== "" && <div style={{marginTop: "10px"}}>备注：{order.content}</div>
                        }
                        <div style={{
                            textAlign: "left",
                            marginTop: "10px",
                            fontWeight: 800,
                            fontSize: "16px"
                        }}>实付款：￥{order.price}</div>
                        {
                            orderState === 1 &&
                            <Input status="error" defaultValue={order.errTip} id="errorInput"
                                   style={{marginTop: "10px"}} placeholder="图片异常提示"/>
                        }
                        <div style={{marginTop: "10px"}}>请给出评分：</div>
                        {
                            data?.cardType === "PSA" && orderState !== 1 &&
                            <div style={{marginTop: "10px", display: "flex", gap: "10px"}}>
                                {
                                    PSAArr.map((item, index) => {
                                        return <Button key={index} onClick={() => setRemark(item)}
                                                       style={{backgroundColor: remark === item ? "gray" : ""}}>{item}</Button>
                                    })
                                }
                            </div>
                        }

                        {
                            data?.cardType === "BGS" && orderState !== 1 &&
                            <div style={{marginTop: "10px", display: "flex", gap: "10px"}}>
                                {
                                    BGSArr.map((item, index) => {
                                        return <Button key={index} onClick={() => setRemark(item)}
                                                       style={{backgroundColor: remark === item ? "gray" : ""}}>{item}</Button>
                                    })
                                }
                            </div>
                        }
                        {
                            data?.signature && orderState !== 1 &&
                            <>
                                <div style={{marginTop: "10px"}}>请给出签字评分：</div>
                                <div style={{marginTop: "10px", display: "flex", gap: "10px"}}>
                                    {
                                        signatureArr.map((item, index) => {
                                            return <Button key={index} onClick={() => setSignatureRemark(item)}
                                                           style={{backgroundColor: signatureRemark === item ? "gray" : ""}}>{item}</Button>
                                        })
                                    }
                                </div>
                            </>
                        }

                        {/*{*/}
                        {/*    orderState !== 1 &&*/}
                        {/*    <TextArea id="remarkText" defaultValue={order.remark} rows={4} style={{marginTop: "10px"}}*/}
                        {/*              placeholder="给出您的建议"/>*/}
                        {/*}*/}
                        {/*<TextArea id="remarkText" defaultValue={order.remark} rows={4} style={{marginTop: "10px"}} placeholder="给出您的建议"/>*/}
                        <div style={{marginTop: "10px"}}>请选择预评结果：</div>
                        <Flex wrap gap="small">
                            <Button style={{backgroundColor: orderState === 1 ? "gray" : ""}}
                                    onClick={() => setOrderState(1)}>
                                图片异常
                            </Button>
                            {/*<Button type="primary" danger>*/}
                            {/*    价格异常*/}
                            {/*</Button>*/}

                            <Button style={{backgroundColor: orderState === 2 ? "gray" : ""}}
                                    onClick={() => setOrderState(2)}>预评完成</Button>

                        </Flex>
                        <div style={{display: "flex", justifyContent: "flex-end"}}><Button type="primary"
                                                                                           onClick={submit}>提交</Button>
                        </div>
                    </Card>
                </div>
            </Card>}
    </Spin>

}
export default Evaluate