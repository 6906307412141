import {Button, Card, Input, Modal, Result, Select, Spin} from "antd";
import {EditOutlined, ReloadOutlined, ShopOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {DataContext} from "../../store/DataContext";
import {useContext, useEffect, useState,} from "react";
import {getOrderList} from "../../config/api";
import dayjs from "dayjs";

const Order = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [orderList, setOrderList] = useState([])
    const [data, setData] = useState([])
    const [refresh, setRefresh] = useState(0)
    const {darkMode} = useContext(DataContext);
    const [selectOption, setSelectOption] = useState(0)
    useEffect(() => {
        setLoading(true)
        getOrderList().then(r => {
            console.log(r)
            let list = r.map(item => {
                let pictures = [item.faceShot,item.backShot,...item.flawList]
                if (item.signature) {
                    pictures.push(item.signature)
                }
                if (item.extraImages) {
                    pictures.push(...item.extraImages)
                }
               return  {...item,createTime: dayjs(item.createTime).format('YYYY-MM-DD HH:MM'),pictures}
            })
            let newList = list.filter(item => item.state === selectOption)
            setOrderList(newList)
            setData(list)
            console.log(list)
            setLoading(false)
        })

        },[refresh])
    useEffect(() => {
        let list = data.filter(item => item.state === selectOption)
        setOrderList(list)
    },[selectOption])
    return <div className="page-container">

            <Spin spinning={loading}>
            <Card title="预评订单">
                <div style={{display: "flex", gap: 20, alignItems: "center", paddingBottom: 20}}>
                    <Select
                        style={{width: 200}}
                        value={selectOption}
                        onChange={(e) => {
                            setSelectOption(e)
                            console.log(e)
                        }}
                        // 0 鉴定中 1 订单异常 2 已完成
                        options={[{
                            label: "鉴定中",
                            value: 0
                        }, {
                            label: "订单异常",
                            value: 1
                        }, {
                            label: "已完成",
                            value: 2
                        }]}
                    />
                    <div><Button onClick={() => setRefresh(refresh + 1)}><ReloadOutlined/></Button></div>
                    <div>总共：{orderList.length}个</div>
                    {/*<div onClick={() => {*/}
                    {/*    setTempData(options.join("\n"))*/}
                    {/*    setEditOption(true)*/}
                    {/*}} style={{color: "#6eddff", cursor: "pointer"}}>*/}
                    {/*    编辑*/}
                    {/*    <EditOutlined/>*/}
                    {/*</div>*/}
                </div>
                <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                {
                        orderList.length > 0 ? orderList.map((item, index) => {
                            return <Card key={index} onClick={() => navigate("/preMark/evaluate?" + item.id)}>
                                <div style={{
                                    fontSize: "16px",
                                    fontWeight: 1000,
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}>
                                    <div><ShopOutlined/> {item.merchant}</div>
                                    <div>{item.createTime}</div>
                                </div>

                                <div style={{display: "flex", gap: "5px", marginTop: "10px"}}>
                                    <div style={{
                                        width: "50px",
                                        textAlign: "center",
                                        padding: "4rpx 10px",
                                        fontSize: "12px",
                                        fontWeight: 800,
                                        color: "#272949",
                                        borderRadius: "30px",
                                        boxSizing: "borderBox",
                                        border: "2px solid gray",
                                        backgroundColor: "white"
                                    }}>{item.cardType}</div>
                                    <div style={{
                                        width: "50px",
                                        textAlign: "center",
                                        padding: "4rpx 10px",
                                        fontSize: "12px",
                                        fontWeight: 800,
                                        color: "#272949",
                                        borderRadius: "30px",
                                        boxSizing: "borderBox",
                                        border: "2px solid gray",
                                        backgroundColor: "white"
                                    }}>{item.thickness}</div>
                                </div>
                                <div style={{display: "flex", gap: "10px", flexWrap: "wrap", marginTop: "10px"}}>
                                    {
                                        item.pictures.map((item, index) => {
                                            return <img key={index} style={{height: "100px"}} src={item} alt="图片"/>
                                        })
                                    }
                                </div>
                                {
                                    item?.content !== "" && <div style={{marginTop: "10px"}}>备注：{item.content}</div>
                                }
                                {
                                    item?.errTip && <div style={{marginTop: "3px"}}>订单异常提示：{item.errTip}</div>
                                }
                                {
                                    item?.remark && <div style={{marginTop: "3px"}}>打分：{item.remark}</div>
                                }
                                {
                                    item?.signatureRemark &&
                                    <div style={{marginTop: "3px"}}>签字打分：{item.signatureRemark}</div>
                                }
                                <div style={{
                                    textAlign: "right",
                                    fontWeight: 800,
                                    fontSize: "16px"
                                }}>实付款：￥{item.price}</div>
                            </Card>
                        }) : loading ? null : <Result
                            status="info"
                            // title="暂无订单"
                            subTitle="暂未找到订单"
                        />
                    }
                </div>
            </Card>
        </Spin>
    </div>
}
export default Order